@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: Work Sans;


}

section {
  display: flex;
  flex-direction: column;
  padding: 25px;

  @media only screen and (max-width: 525px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  
}
p {
  white-space: pre-wrap;
}